import {AfterViewInit, Component, EventEmitter, OnInit, ViewEncapsulation} from '@angular/core';
import {BrandService} from './services/brand.service';
import {StockApiService} from './services/stock-api.service';
import {ObservedOfferService} from './services/observed-offer.service';
import {ComparedOfferService} from './services/compared-offer.service';
import {NavigationEnd, Router} from '@angular/router';
import {environment} from '../environments/environment';
import {Brand, Brands} from './models/Brands';
import {QueryStringService} from './services/query-string.service';
import {LocalStorageService} from './services/local-storage.service';
import {ConfigModel} from './config/config';
import {EmptyModalComponent} from './modals/empty-modal/empty-modal.component';
import {LiveChatService} from './services/live-chat.service';
import {ErrorHandler} from './tools/error-handler';
import {LabelProviderService} from './services/labelProvider.service';
import {TrackingService} from './services/tracking.service';

// declare ga as a function to set and sent the events
declare let ga: Function;
declare let sitedata: any;

@Component({
  providers: [
    BrandService
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements AfterViewInit {
  static isDealerStock = false;
  static dealerCode: string = null;
  static changeStockDealer: EventEmitter<string> = new EventEmitter();
  static bodyClass = 'home';
  static goToHomepage: EventEmitter<any> = new EventEmitter();
  static THROW_404_ERROR = 'Throw 404 error';


  brand: Brand;
  isBrandLoaded = false;
  footerLinkToBrand = '';
  brandConfig: ConfigModel = null;
  debuggerEnabled = false;
  trackingDebuggerEmitter = new EventEmitter();
  show404Error = false;
  public classReference = AppComponent;
  currentModel = '0';
  QSSmodels: any;
  showNavListMenu: false;
  lfaUrl = null;

  constructor(
    private brandService: BrandService,
    private stockApiService: StockApiService,
    private router: Router,
    public observedOffers: ObservedOfferService,
    public comparedOffers: ComparedOfferService,
    private QSS: QueryStringService,
    private LSS: LocalStorageService,
    private liveChatService: LiveChatService,
    private labelProviderService: LabelProviderService,
  ) {
    let code = null;
    if (/\/dealer\-site\/\d{7}/.test(location.href)) {
      code = location.href.match(/\/dealer\-site\/(\d{7})/)[1];
    }
    this.getBrands(code).then(() => {
      if (environment.version === 'dev') {
        if (typeof BrandService.getCurrentBrand().slug !== 'undefined') {
          $('head').append('<link rel="stylesheet" href="/assets/css/style-' +
            BrandService.getCurrentBrand().slug +
            '.css" type="text/css"/>');
        } else {
          $('head').append('<link rel="stylesheet" href="/assets/css/style-fiat.css" type="text/css"/>');
        }
      }
      const ua = navigator.userAgent;
      const is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
      if (is_ie) {
        $('head').append('<link rel="stylesheet" href="/assets/css/ie-style.css" type="text/css"/>');
      }

      this.isBrandLoaded = true;
      this.afterBrandLoad();
    }).catch(reason => {
      if (reason.message && reason.message !== AppComponent.THROW_404_ERROR) {
        console.log(reason);
        ErrorHandler.handle(reason);
      }
    });
  }

  async getBrands(code): Promise<void> {
    if (sitedata !== undefined && sitedata.brands) {
      BrandService.setData(sitedata.brands);
      if (sitedata.show404Error && sitedata.show404Error === true) {
        this.show404Error = true;
      } else {
        this.labelProviderService.init();
      }
    } else {
      const brands = await this.stockApiService.getBrands(code);
      BrandService.setData(brands);
      this.labelProviderService.init();
    }
  }

  afterBrandLoad() {
    this.brandConfig = BrandService.getConfig();
    this.sendMasterkeyData();

    if (environment.version !== 'dev') {
      this.loadGoogleAnalytics();
    }

    if (BrandService.getConfig()) {
      this.brand = BrandService.getCurrentBrand();

      this.footerLinkToBrand = this.brandConfig.homeSite;

      const host = location.protocol + '//' + location.host;
      this.changeRoute($(location).attr('href').slice(host.length));
      this.router.events.subscribe((value: NavigationEnd) => {
        if (value instanceof NavigationEnd) {
          if (TrackingService.isEnabled(TrackingService.GOOGLE_ANALYTICS) && typeof ga !== 'undefined') {
            ga('set', 'page', value.urlAfterRedirects);
            ga('send', 'pageview');
          }
          this.changeRoute(value.urlAfterRedirects);
        }
      });
    }

    if (this.brand.slug === 'fiat-professional') {
      this.lfaUrl = {
        configurator: 'https://www.fiatprofessional.com/pl/konfigurator',
        testDrive: 'https://www.fiatprofessional.com/pl/jazda-probna',
        dealers: 'https://www.fiatprofessional.com/pl/znajdz-dealera'
      };
    } else if (this.brand.slug === 'fiat') {
      this.lfaUrl = {
        configurator: 'https://www.fiat.pl/configurator',
        testDrive: 'https://www.fiat.pl/jazda-testowa',
        dealers: 'https://www.fiat.pl/dealerzy'
      };
    }

  }

  private loadGoogleAnalytics(number = 0) {
    if (TrackingService.isEnabled(TrackingService.GOOGLE_ANALYTICS)) {
      if (typeof ga !== 'undefined') {
        ga('create', environment.production ? this.brandConfig.googleAnalyticsKey : false, 'auto');
        ga('set', 'page', document.location.pathname);
        ga('send', 'pageview');
      } else {
        if (number <= 10) {
          setTimeout(() => {
            this.loadGoogleAnalytics(number + 1);
          }, 500);
        }
      }
    }
  }

  private sendMasterkeyData() {
    const data = {
      cid: this.QSS.getParam('cid'),
      oid: this.QSS.getParam('oid'),
      source: this.QSS.getParam('source'),
      campaignid: this.QSS.getParam('campaignid'),
      offerid: this.QSS.getParam('offerid'),
      bannerid: this.QSS.getParam('bannerid'),
      advertiserid: this.QSS.getParam('advertiserid'),
      publisher: this.QSS.getParam('publisher'),
    };


    if (data.cid !== null
      || data.oid !== null
      || data.source !== null
      || data.campaignid !== null
      || data.offerid !== null
      || data.bannerid !== null
      || data.advertiserid !== null
      || data.publisher !== null
    ) {
      this.stockApiService.sendMasterkeyData(data).catch(reason => {
        ErrorHandler.handle(reason);
      });
    }
  }

  ngAfterViewInit() {
    if (this.QSS.getParam('enable_stock_debugger') !== null) {
      this.LSS.setMultiBrand('stockdebug', true);
      this.debuggerEnabled = true;
    } else {
      if (this.LSS.getMultiBrand('stockdebug')) {
        this.debuggerEnabled = true;
      }
    }
  }

  private changeRoute(url: string) {
    const queryParamsCharPos = url.indexOf('?');
    let route: string;
    if (queryParamsCharPos > -1) {
      route = url.substr(0, queryParamsCharPos);
    } else {
      route = url;
    }

    if (/\/dealer\-site\/\d{7}/.test(route)) {
      const code = route.match(/\/dealer\-site\/(\d{7})/)[1];
      if (code !== AppComponent.dealerCode) {
        AppComponent.isDealerStock = true;
        AppComponent.changeStockDealer.emit(code);
        AppComponent.dealerCode = code;
        // this.stockApiService.getBrands(code).then(value => {
        //   this.brandService.setNewDataWithoutBrandChanging(value);
        // }).catch(reason => {
        //   document.location.href = '/page-not-found'
        //   ErrorHandler.handle(reason);
        // });
      }
    } else {
      if (AppComponent.dealerCode !== null) {
        AppComponent.isDealerStock = false;
        AppComponent.changeStockDealer.emit(null);
        AppComponent.dealerCode = null;
        // this.stockApiService.getBrands().then(value => {
        //   this.brandService.setNewDataWithoutBrandChanging(value);
        // }).catch(reason => {
        //   ErrorHandler.handle(reason);
        // });
      }
    }
  }

  openPrivacyNotes() {
    $('body').addClass('cursor-progress');
    this.stockApiService.getPrivacyNotes().then(value => {
      EmptyModalComponent.openModal(value, true);
      $('body').removeClass('cursor-progress');
    }, reason => {
      $('body').removeClass('cursor-progress');
      ErrorHandler.handle(reason);
    });
  }

  generateNumberRandomForBanner(numberOfBanners) {
    return Math.floor(Math.random() * numberOfBanners);
  }

  clickLogo() {
    if (this.show404Error) {
      document.location.href = BrandService.getCurrentBrand().stockUrl;
    }
    AppComponent.goToHomepage.emit();
  }

  openCookieBar() {
    $('.cookie-info').trigger('click');
  }

  getLiveChatLicense() {
    return this.liveChatService.livechatLicense;
  }

  getDealerLiveChatGroup(dealerCode) {
    const group = this.liveChatService.getDealerLiveChatGroup(dealerCode);

    if (group !== false) {
      $('#livechat-compact-container').show(0);
      $('#livechat-eye-catcher').show(0);
    } else {
      this.addDealerTawkTo(dealerCode);
    }

    return group;
  }

  addDealerTawkTo(dealerCode) {
    const code = this.liveChatService.getDealerTawkToCode(dealerCode);

    if (code !== false && $('[title="tawk-to"]').length === 0) {
      $('head').append('<script title="tawk-to" src="https://embed.tawk.to/' + code + '/default" charset="UTF-8" async></script>');
    }
  }

  onActivate(elementRef) {
      if (typeof elementRef.currentModel !== 'undefined') {
        elementRef.currentModel.subscribe(event => {
          this.currentModel = event;
        });
      } else {
        this.currentModel = '0';
      }
  }

  checkQSS() {
    this.QSSmodels = this.QSS.getParam('models');
    return 1;
  }

  closeDebugger() {
    this.LSS.removeMultiBrand('stockdebug');
    this.debuggerEnabled = false;
  }

  openOrCloseNavListMenu(value) {
    this.showNavListMenu = value;
  }
}
